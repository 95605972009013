import styled from 'styled-components';
import { Tabs } from 'react-bootstrap';

const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';

const Layout = styled.div`
  width: 100%;
  padding: 30px;
  float: left;
  font-family: ${RegularFont};
  
  @media (max-width: 500px) {
    padding: 30px 10px;
  }
`;

const PageTitle = styled.h1`
  font-size: 24px;
  letter-spacing: 1px;
  color: rgb(51,51,51);
  font-family: ${RegularFont};
  margin: 40px 33px 10px;

  @media (max-width: 767px) {
    margin: 40px 20px 10px;
  }
`;

const StyledTabContainer = styled(Tabs)`
  > ul {
    // border-bottom: 1px solid rgb(210, 206, 206);
    padding: 20px 20px 1px 33px;
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(153,153,153);
    // margin-bottom: 3px;
    @media (max-width: 767px) {
      padding: 20px 10px 1px;
    }

    > li {
      margin-bottom: -3px;

      @media (max-width: 500px) {
        width: calc(100%/3);
        text-align: center;
      }
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: ${BoldFont};
        margin-right: 85px;
        text-transform: uppercase;
        padding: 5px 5px 17px 5px;
        border:none;
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-bottom: none;
        }

        @media (max-width: 767px) {
          margin-right: 14vw;
        }

        @media (max-width: 500px) {
          width: auto;
          margin: 0 7vw;
        }
      }
    }

    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: #0D4270;
        border: none;
        border-bottom: 3px solid #0D4270;
        cursor: pointer;
        font-family: 'Rubik-Medium';

        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid #0D4270;
          color: #0D4270;
        }
      }
    }
  }
`;

const AssessmentContainer = styled.div`
  background: ${({bgColor}) => bgColor ? 'linear-gradient(180deg, #D94793 0%, #A7124E 100%)' : 'linear-gradient(180deg, #37ED7D 0%, #129A8E 100%)'};
  width: 100%;
  font-family: ${BoldFont};
  display: block;
  color: white;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const PhysicianContainer = styled.a`
  background: ${({bgColor}) => bgColor ? 'linear-gradient(0deg, rgba(131,128,32,1) 0%, rgba(206,201,62,1) 100%)' : 'linear-gradient(180deg, #D94793 0%, #A7124E 100%)'};
  margin-bottom: ${({ noMargin }) => !noMargin && '20px'};
  width: 100%;
  float: left;
  font-family: ${BoldFont};
  display: block;
	color: white;
	border-radius: 4px;
  &:hover, &:focus{
    color: white;
    outline: none;
    border: none;
    text-decoration: none;
  }
`;

const TipInformation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding: 23px 20px;
  cursor: pointer;
  position: ${({isIE11}) => isIE11 ? 'relative' : 'static'};
  > span {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
  > input {
    cursor: pointer;
    text-indent: ${({isIE11}) => isIE11 ? '-999em' : '0em'};
    opacity: 0;
    z-index: ${({isIE11}) => isIE11 ? '1' : '-1'};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const InformationIcon = styled.div`
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
  text-align: center;
  display: inline-block;
  margin-right: 9px;
  font-family: ${BoldFont};
`;

const UserPostContainer = styled.div`
  width: 65%;
  margin-right: 35px;
  >div{
    >div{
      width: 100%;
    }
  }
`;

const PersonDetailCard = styled.div`
max-width: 1246px;
width: 100%;
margin: auto;
padding:25px;
.rewards{
  width: 100%;
  position:relative;
  height:0px;
  >img{
    width: 42px;
    height: 57px;
    position:relative;
    z-index: 10;
  }
}
.card-wrapper{
  max-width: 1246px;
  height: 190px;
  display:flex;
  margin-bottom: 10px;
  background: #FFFFFF;
  width: 100%;
  padding: 15px;
  border-radius: 6px;
}

    .profile-img-section{
        width:160px;
        height:160px;
        >img{
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
    }
    #person-img{
        height:160px;
        width:160px;
    }

    .profile-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .username-location-wrapper{
          height:40%;
        }
        .points-section-wrapper{
          
        }

.user-name{
  font-family: 'Rubik-Medium';
  font-size: 18px;
  color: #0D4270;
  margin-left: 25px;
  margin-bottom: 10px;
  display: flex;
  width:100%;
  .wellness-champion{
    width: 130px;
    height:16px;
    margin: auto;
    margin-left:10px;
    background-image : url("/images/PeoplePage/wellnessChampion.png");
    background-size: 100% 100%;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;

  }
}
  }

        .icons-section{
            display: flex;
            padding-left:5px;
            .place-icon{
                display: flex;
            }
            .flag{
              width:10.21px;
              height:17.31px;
              margin-left:20px
            }
            .text{
              height: 20px;
              margin-left:5px;
            }
            .location{
                // width:65%;
                margin-left: 15px;
                color:#0D4270;
            }
            .flag2{
              width:14.81px;
              height:20px;
              margin-right:5.19px;
              margin-left:5px;
            }
            .userRoleIcon{
              width:auto;
              height:20px;
              margin-right:5.19px;
              margin-left:5px;
            }
        }
         
        .points-section{
          display: flex;
          .my-points{
            border: 1px dashed rgba(156, 156, 156, 0.3);
            border-radius: 3px;
            margin-left:25px;
            width: 120px;
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;
            .my-points-txt{
              opacity :0.5;
              font-size:14px;
            }
            .my-points-val{
              color:#FD7175;
              font-size:24px;
              font-weight: 700;
            }
          }
            
            .my-buddies{
              border: 1px dashed rgba(156, 156, 156, 0.3);
              border-radius: 3px;
              margin-left:15px;
              width: 120px;
              height: 70px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 10px;
            }
            .my-buddies-txt{
              opacity :0.5;
              font-size:14px;
            }
            .my-buddies-val{
                color:#69C2FF;
                font-size:24px;
                font-weight: 700;
            }
        }
    }
    
    .buttons-wrapper{
      display: flex;
      margin: auto;
      margin-right: 0px;
      margin-bottom: 0px;
      justify-content: flex-end;
      align-items: flex-end;
    }
        .user-role-btn{
            width: 150px;
            height: 40px;
            background-color:#69C2FF;
            color:white;
            border:none;
            border-radius: 3px;
            font-size:110%;
            font-family:"Rubik-Medium";
            margin-right: 10px;
        }
        .remove-role-btn{
            width: 150px;
            height: 40px;
            background: rgba(156, 156, 156, 0.6);
            color:white;
            border:none
            border-radius: 3px;
            font-size:110%;
            font-family:"Rubik-MEdium";
        }
    }

    > div: second-child {
        width: 100%;
        max-width: 1246px;
        height: 190px;
        display:flex;
      }

`;

const StyledTabContainerV2 = styled(Tabs)`
   float: left;
   margin-bottom:25px
  > ul {
    height: ${({ newPadding }) => newPadding ? '70px' : 'none'};
    border-bottom: none;
    padding: ${({ newPadding }) => newPadding ? '16px 10px 12px 18px' : '20px 20px 1px 33px'};
    font-size: 12px;
    text-transform: capitalize;
    color: rgb(153,153,153);
    background-color: #fff;
    
    
    > li {
      width:131px;
      height:40px;
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: "Rubik-Medium";
        margin-right: 5vw;
        text-transform: capitalize;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;


const StyledTabsNew = styled(StyledTabContainerV2)`
  width: 100%;
  .tab-content {
    position: relative;
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    margin: 0;
    border-radius: 6px;
    padding: 10px 15px 10px 15px;
    margin-bottom:10px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
      
    }
    > li {
      width: 18.5%;
      display: flex;
      justify-content: center;
      height: 30px;
      margin-right: ${({right}) => right ? "15px" : "51px"};
      width: 139px;
      height: 40px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 16px;
        color: #CCCBDO;
        text-transform: none;
        font-family: "Rubik-Medium";
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0;
        padding: 10px 0px;

        font-family: 'Rubik-Regular';
        font-size: 16px;
        line-height: 21px;
        color:#9C9C9C;
       }
      }
    }


    > li.second-tab {
      width: 18.5%;
      display: flex;
      justify-content: center;
      height: 30px;
      margin-right: ${({right}) => right ? "15px" : "51px"}
      width: 158px;
      height: 40px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 16px;
        color: #CCCBDO;
        text-transform: none;
        font-family: "Rubik-Regular";
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0;
        padding: 10px 0px;

        font-family: 'Rubik-Regular';
        font-size: 16px;
        line-height: 20px;
        color:#9C9C9C;
       }
      }
    }

    > li.third-tab {
      width: 18.5%;
      display: flex;
      justify-content: center;
      height: 30px;
      margin-right: ${({right}) => right ? "15px" : "51px"}
      width: 152px;
      height: 40px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 16px;
        color: #CCCBDO;
        text-transform: none;
        font-family: "Rubik-Regular";
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0;
        padding: 10px 0px;

        font-family: 'Rubik-Regular';
        font-size: 16px;
        line-height: 20px;
        color:#9C9C9C;
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.second-tab.active {
    width: 170px;
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.third-tab.active {
    width: 166px;
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.first-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  li.second-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  li.third-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  > ul > li > a{
    padding: 0px;

    font-family: 'Rubik-Regular';
    font-size: 16px;
    line-height: 20px;
    color:#9C9C9C;
  }
`;

export { Layout, StyledTabContainer, PageTitle, InformationIcon, TipInformation, AssessmentContainer, PhysicianContainer, UserPostContainer, StyledTabsNew, PersonDetailCard};
